@charset "UTF-8";
.body-wrapper {

/*********************
    Common Styles
********************/
.clear {
    clear: both;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

/***********************
    Wrappers/Layouts
***********************/

.wrapper {
    max-width:1385px;
    width: 100%;
    margin: 0 auto;
    position:relative;
    padding: 0 25px;
    .clearfix();

    &.small {
        max-width: 980px;
    }
}

.block {
    position: relative;
    .clearfix();

    &.padded {
        padding: 65px 0;
    }

    &.padded-top {
        padding: 65px 0 0;
    }

    &.padded-bottom {
        padding: 0 0 65px;
    }

    &.white {
        background-color: @white;
    }

    &.black {
        background-color: @grey3;
        h2, h3, p, a {
            color: @white;
        }
        a.btn{
            color: @main_colour_alt;
            &:hover {
                color: @white;
            }
        }
    }

    &.grey {
        background-color: @tertiary_colour;
    }

    &.blue {
        background-color: @main_colour_alt;
        h2, h3, p, a {
            color: @white;
        }
        a.btn{
            color: @main_colour_alt;
            &:hover {
                color: @white;
            }
        }
    }

    &.dark_blue {
        background-color: @main_colour;
        h2, h3, p, a {
            color: @white;
        }
        a.btn{
            color: @main_colour_alt;
            &:hover {
                color: @white;
            }
        }
    }
}

.text-center {
    text-align: center;
}

.equal-cols {
    width: 100%;
    margin: 0;
    padding: 0;


    &:after {
        clear: both;
        content: '';
        display: block;
    }

    .col {
        width: 45%;
        width: ~"calc(50% - 30px)";
        float: left;
        margin-right: 60px;
        &:last-of-type {
            margin-right: 0;
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
}

.two-thirds-col {
     .col-2-3 {
         width: 64%;
         width: ~"calc(66.66% - 30px)";
         float: left;
         margin-right: 60px;
         img {
             display: block;
             margin: 0 auto;
             max-width: 100%;
             height: auto;
         }
     }
     .col-1-3 {
         width: 31%;
         width: ~"calc(33.33% - 30px)";
         float: left;
         margin: 0;

         img {
             display: block;
             margin: 0 auto;
             max-width: 100%;
             height: auto;
         }
     }
 }

.thirds-columns {
    position: relative;
    .clearfix();
    .col {
        display: block;
        float: left;
        width: 31%;
        width: ~"calc(33% - 30px)";
        margin-right: 45px;
        &:nth-child(3){
            margin-right: 0;
        }
    }
}

.vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}

/**********************
    Content Styles
**********************/
.content {
    ul {
        margin-bottom: 15px;
        .clean-list();
        li {
            padding-left: 25px;
            padding-bottom: 15px;
            &:before {
                content: '\e802';
                font-family: 'fontello';
                display: inline-block;
                margin: 0 0 0 -15px;
                width: 15px;
                font-size: 12px;
            }

            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }

    ol {
        margin-bottom: 15px;
        .clean-list();
        li {
            counter-increment: step-counter;
            padding-left: 25px;
            padding-bottom: 15px;
            &:before {
                content: counter(step-counter);
                font-weight: 500;
                display: inline-block;
                margin: 0 0 0 -25px;
                width: 15px;
                font-size: 12px;
            }
        }
    }

    iframe {
        max-width: 100%;
        height: auto;
    }
}


/**********************
      Page Banner
**********************/
.page-banner {
    display: block;
    position: relative;
    background: url('@{img_dir}gradient-bg-small.png') no-repeat center;
    background-size: cover;
    padding: 97.5px 0;
    margin-top: -98px;
    text-align: center;
    h1 {
        margin-top: 98px;

        span {
            font-size: 18px;
            display: block;
            margin-bottom: 5px;
            text-transform: uppercase;
        }
    }
}

/**********************
     Page Banner
**********************/
.cta-block {
    display: block;
    position: relative;
    background: @secondary_colour;

    h2, p {
        color: @white;
    }

    h2 {
       margin-bottom: 25px;
    }

    p {
        margin-bottom: 60px;
    }
}

/***********************
   Pagination Block
***********************/
.pagination-block {
    .pagination {
        text-align: center;
        .pagination-buttons {
            width: 100%;
            display: block;
            margin: 0 auto 35px;
            .clearfix();
        }

        a {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }

        ul {
            .clean-list();
            li {
                display: inline-block;
                padding: 0 20px;
                &:before {
                    display: none;
                }
            }
        }
    }
}


/**********************
   Image Right Block
**********************/
.image-right-block {
    .equal-cols {
        .col {

            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                height: auto;
            }

            &.pull-right {
                float: right;
                margin-right: 0;
            }

            &:last-of-type {
                margin-right: 60px;
            }
        }
    }
    &.full-height {
        .text-col {
            padding: 65px;
            .text-inner {
                max-width: 65%;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -o-transform: translateX(-50%);
            }
        }

        .image-col {
            img {
                width: 100%;
            }
        }
    }

    .text-col {
        padding: 65px 0;
    }

}

/**********************
   Image Left Block
**********************/
    .image-left-block {

        &.full-height {
            .text-col {
                padding: 65px;
                .text-inner {
                    max-width: 65%;
                    position: relative;
                    left: 50%;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                }
            }
        }

        .text-col {
            padding: 65px 0;
        }

        .image-col {
            img {
                width: 100%;
            }
        }
    }

    /**********************
        Image Left Block
    **********************/
    .image-text-list-block {

        .list-item {
            padding: 65px 0;
            border-bottom: 3px solid @greyF;
        }

        .equal-cols {
            .col {
                &.pull-right {
                    float: right;
                }

                img {
                    margin: 0 auto;
                }
            }
        }
    }



    /**********************
          Logo Block
    **********************/
.logo-block {
    .logo-list {
        .clean-list();
        .logo {
            display: inline-block;
            margin-right: 35px;
            margin-bottom: 35px;

            &:before {
                display: none;
            }

            &:nth-child(4n){
                margin-right: 0;
            }
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
            }
        }
    }
}




}//close body-wrapper
