@charset "UTF-8";

.form-block {
    .gform_wrapper {
        margin: 0;
        form {
            ul.gform_fields {
                li {
                    padding-left: 0;

                    &:before {
                        display: none;
                    }

                    .gfield_label {
                        display: none;
                    }

                    .ginput_container {
                        margin-top: 0;
                    }

                    &.gfield {
                        clear: none;
                        margin-top: 0;
                        padding-bottom: 25px;
                        padding-right: 25px;

                        &.gf_left_half {
                            float: left;
                            clear: none;
                            display: block;
                        }

                        &.gf_right_half {
                            float: left;
                            clear: none;
                            display: block;
                        }

                        &.full_width {
                            clear: both;
                        }
                    }
                    input, textarea {
                        width: 100%;
                        font-size: 16px;
                        padding: 14px 15px !important;
                        color: @form_input_text_colour;
                        background: @form_input_bg_colour;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        .transition(all, 0.3s);
                        .placeholder({ color: @form_input_placeholder_colour; });
                        border: 1px solid @form_input_border;
                        border-radius: 0;
                        line-height: 120%;
                        &:focus {
                            outline: none;
                            border-color: @form_input_border_focus !important;
                            .placeholder({ color: @form_input_placeholder_colour; });
                        }
                        &:disabled {
                            cursor: not-allowed;
                        }
                    }
                }
            }
            .gform_footer {
                margin: 0;
                padding: 0;

                input[type=submit] {
                    background-color: transparent;
                    border: 2px solid @main_colour_alt;
                    padding: 14px 35px;
                    color: @main_colour_alt;
                    outline: none;
                    transition: 0.1s;
                    .defaultFont(500);

                    &:hover {
                        background-color: @main_colour_alt;
                        color: @white;
                    }
                }
            }
        }
    }
}
