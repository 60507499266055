@charset "UTF-8";

/****************
    Fonts
*****************/

.Ubuntu(@weight:400,@style:normal) {
    .font-mixin(~"'Ubuntu','Helvetica Neue','Helvetica',Arial,sans-serif", @weight, @style);
}

.defaultFont(@weight:400,@style:normal) {
    .Ubuntu(@weight, @style);
}

html, body {
    .defaultFont();
    .smooth();
}

/****************
    Text Styles
*****************/

.defaultH1() {
    display: block;
    font-size: 60px;
    margin: 0 0 40px;
    color: @defaultH1;
    .defaultFont(400);
}

.defaultH2() {
    color: @defaultH2;
    font-size: 35px;
    margin: 0 0 35px;
    .defaultFont(500);
}

.defaultH3() {
    color: @defaultH3;
    font-size: 25px;
    margin: 0 0 30px;
    .defaultFont(500);
}

.defaultH4() {
    color: @defaultH4;
}

.defaultH5() {
    color: @defaultH5;
}

.defaultH6() {
    color: @defaultH6;
}

.defaultP() {
    color: @defaultP;
    .defaultFont(400);
    font-size: 16px;
    line-height: 155%;
    margin: 0 0 15px 0;

    strong {
        font-weight: 700;
    }

    em {
        font-style: italic;
    }

    &.intro {
        //width: 70%;
        font-size: 22px;
        line-height: 155%;
        color: @intro_colour;
    }

    &.h1 {
        display: block;
        font-size: 60px;
        margin: 0 0 40px;
        color: @defaultH1;
        .defaultFont(400);
    }
}

.defaultA() {
    color: @default_a_colour;
    .transition(color, 0.1s);
    text-decoration: none;
    .defaultFont(400);
    &:hover {
        color: @default_a_hover_colour;
        text-decoration: none;
    }
    &:active {
        color: @default_a_active_colour;
    }

    &.feature-link {
        text-decoration: underline;
        i {
            content: '\e802';
            font-family: 'fontello';
            font-size: 12px;
            padding-left: 15px;
            text-decoration: none;
        }
    }
}

.defaultLi() {
    li {
        .defaultP();
        margin: 0;
    }
}

.defaultUl {
    padding-left: 30px;
    list-style: disc;
    margin: 15px 0;
    .defaultLi();
    li {

    }

}

.defaultOl {
    list-style: decimal;
    padding-left: 30px;
    margin: 15px 0;
    .defaultLi();
    li {

    }
}

.defaultTable() {
    width: 100%;
    margin: 0 0 20px;
}

.defaultTextStyles() {
    h1 {
        .defaultH1();
    }
    h2 {
        .defaultH2();
    }
    h3 {
        .defaultH3();
    }
    h4 {
        .defaultH4();
    }
    h5 {
        .defaultH5();
    }
    h6 {
        .defaultH6();
    }
    p {
        .defaultP();
    }
    a {
        .defaultA();
    }
    ul {
        .defaultUl();
    }
    ol {
        .defaultOl();
    }
    table {
        .defaultTable()
    }
}

/*
 *  Print Styles are declaring them
 */

.defaultTextStyles();


/**********************
       Btn stylings
   ***********************/

.btn {
    .btn-base();
    line-height: 100%;
    padding: 15px 35px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    .transition(all, 0.1s);
}

.btn-primary {
    background: @primary_btn_bg;
    color: @primary_btn_colour;
    border-radius: 28px;
    &:hover {
        color: @primary_btn_colour_hover;
        background: @primary_btn_bg_hover;
    }
    &:active {
        color: @primary_btn_colour_active;
        background: @primary_btn_bg_active;
    }
    &:disabled {
        color: @primary_btn_colour_disabled;
        background: @primary_btn_bg_disabled;
    }
}

.btn-secondary {
    background: @primary_btn_bg;
    color: @primary_btn_colour;
    border: 1px solid @main_colour_alt;
    text-transform: uppercase;
    font-weight: 400;

    &:hover {
        color: @primary_btn_colour_hover;
        background: @primary_btn_bg_hover;
    }
    &:active {
        color: @primary_btn_colour_active;
        background: @primary_btn_bg_active;
    }
    &:disabled {
        color: @primary_btn_colour_disabled;
        background: @primary_btn_bg_disabled;
    }
}