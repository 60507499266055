@charset "UTF-8";
/* CSS Document */
.body-wrapper {

/*********************
    max-width 1000
**********************/

@media screen and (max-width: 1000px) {
    .tab-navigation-block {
        .tab-menu {
            ul {
                position: relative;
                width: 100%;
                li {
                    width: 50%;
                }
            }
        }
    }
}

/*********************
    max-width 979
**********************/

@media screen and (max-width: 979px) {
    header {
        .main-navigation {
            ul {
                li {
                    a {
                        padding: 14px 15px 14px 0;
                    }
                }
            }
        }
    }

    footer {
        .right {
            float: left;
        }

        .footer-navigation {
            margin-bottom: 35px;
            ul {
                li {
                    a {
                        padding: 0 15px 0 0;

                    }
                }
            }
        }

        img {
            float: left;
        }

        .digital-agency {
            text-align: left;
        }
    }

    .homepage-banner-block {
        min-height: 0;
        .homepage-ctas {
            margin-top: 0;
            .cta-container {
                position: relative;

                &:last-of-type {
                    position: relative;
                    margin-top: 0;
                }

                .underlay {
                    display: none;
                }

                .cta {
                    width: 100%;
                    height: auto;
                    border: none;
                    float: none;
                    top: 0;
                    margin-bottom: 35px;
                    background-color: rgba(255, 255, 255, 0.65);
                    padding: 35px 25px;

                    &:hover {
                        margin-top: 0;
                        border: none;

                        &:after {
                            border: none;
                        }
                    }

                    .front {
                        display: none;
                    }

                    .back {
                        opacity: 1;
                        visibility: visible;
                        position: relative;
                        width: 100%;
                        -webkit-transform: translate(0, 0);
                        -moz-transform: translate(0, 0);
                        -ms-transform: translate(0, 0);
                        -o-transform: translate(0, 0);
                        transform: translate(0, 0);
                        height: auto;
                        p {
                            &.round-button {
                                position: relative;
                                bottom: 0;
                                left: 0;
                                margin: 0 auto;
                            }
                        }
                    }


                    &:after {
                        display: none;
                    }
                }

                &:last-of-type {
                    .cta {
                        float: none;
                        border: none;
                    }
                }
            }
        }
    }
}

/*********************
    max-width 900
**********************/
@media screen and (max-width: 900px) {
    header {
        .menu-trigger {
            display: block;
        }

        .main-navigation {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            left: 0;
            top: 98px;
            transition: 0.1s;
            width: 100%;

            &.open {
                opacity: 1;
                visibility: visible;

                background-color: @white;
            }

            ul {
                border-top: 1px solid @greyD;
                padding: 0 25px;
                float: none;
                li {
                    display: block;
                    a {
                        padding: 14px 15px 14px 0;
                    }

                    &.has-children {
                        .nav-drop{
                            display: none;
                        }

                        &:hover {
                            .nav-drop {
                                display: none;
                            }
                        }
                    }
                }

                &.tour-cta {
                    border-top: none;
                    padding-top: 0;
                    background-color: @main_colour_alt;
                    li {
                        a {
                            color: @white;
                        }
                    }
                }
            }
        }
    }
}

/*********************
    max-width 769
**********************/

@media screen and (max-width: 769px) {

    .vertical-align {
        top: 0;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    .block {
        &.padded {
            padding: 35px 0;
        }

        &.padded-top {
            padding: 35px 0 0;
        }

        &.padded-bottom {
            padding: 0 0 35px;
        }
    }

    h1 {
        font-size: 32px;
        margin-bottom: 35px;
    }

    h2 {
        font-size: 25px;
        margin-bottom: 25px;
    }

    h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 15px;
        &.intro {
            font-size: 19px;
        }

        &.h1 {
            font-size: 38px;
        }
    }

    footer {

        .left {
            float: none;
        }

        .right {
            float: none;
        }

        .footer-navigation {
            text-align: center;
            ul {
                li {
                    margin-bottom: 15px;
                }
            }
        }

        .footer-subnav {
            text-align: center;
        }

        img {
            float: none;
            display: block;
            margin: 0 auto 35px;
        }

        .digital-agency {
            text-align: center;
        }
    }

    .page-banner {
        margin-top: 0;
        padding: 45px;
        h1 {
            margin: 0;
        }
    }

    .equal-cols {
        .col {
            width: 100%;
            float: none;
            margin: 0 0 35px;

            &.image-col {
                margin: 0;
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }

    .two-thirds-col {
        .col-2-3 {
            width: 100%;
            margin-bottom: 25px;
        }

        .col-1-3 {
            width: 100%;
        }
    }

    .testimonial-block {
        .testimonial-slider-for {
            .overlay {
                padding: 150px 25px;
            }
        }
    }

    .tab-navigation-block {
        padding: 0;
        .tab-menu {
            ul {
                margin-top: 0;
                li {
                    a {
                        background-color: @tertiary_colour;
                        &:hover {
                            background-color: @tertiary_colour;
                        }
                    }
                }
            }
        }
    }

    .post-list-block {
        .post {
            padding: 95px 35px;
        }
    }

    .form-block {
        .gform_wrapper {
            form {
                ul.gform_fields {
                    li {
                        padding-right: 0;
                        &.gfield {
                            &.gf_left_half {
                                width: 100%;
                            }
                            &.gf_right_half {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .product-showcase-block {
        .product {
            padding: 65px 45px;
        }
    }

    .product-information-block {
        .thirds-columns {
            .col {
                width: 100%;
                margin: 0 0 25px;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    .product-intro-block {
        .download-button {
            position: relative;
            .clearfix();
        }
    }

    .product-benefits-block {
        ul {
            li {
                width: 100%;
                margin-right: 0;
            }
        }
    }

    .product-gallery-block {
        .product-image-slider {
            max-height: 405px;
        }
    }

    .product-logo-block {
        position: relative;
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
        max-width: 100%;
        width: 100%;
        border-left: none;
        border-right: none;
        img {
            max-width: 100px;
        }
    }

    .logo-block {
        .logo-list {
            .logo {
                float: left;
                width: ~"calc(50% - 17.5px)";
                margin-right: 35px;

                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    .image-right-block {
        .equal-cols {
            .col {
                &.pull-right {
                    float: none;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .text-col {
            padding: 25px 0;
        }

        .text-inner {
            max-width: 100%;
        }

        &.full-height {
            .text-col {
                padding: 25px;
            }
        }
    }

    .image-left-block   {
        .text-col {
           padding: 25px 0;
        }

        .text-inner {
            max-width: 100%;
        }

        &.full-height {
            .text-col {
                padding: 25px;
            }
        }
    }
}

/*********************
    max-width 589
**********************/

@media screen and (max-width: 589px) {
    footer {
        padding: 25px;
    }

    p {

        &.h1 {
            font-size: 32px;
        }
    }

    a {
        &.feature-link {
            i {
                padding-left: 0;
            }
        }
    }

    .btn {
        display: block;
        font-size: 14px;
    }

    .tab-navigation-block {
        .tab-menu {
            ul {
                li {
                    width: 100%;
                    a {
                        padding: 15px 25px;
                    }
                }
            }
        }
    }

    .product-showcase-block {
        .product {
            width: 100%;
            padding: 45px 25px;
        }
    }

    .post-list-block {
        .post {
            width: 100%;
            padding: 45px 25px;

            h3 {
                font-size: 20px;
                margin-bottom: 25px;
            }

            p {
                &.link {
                    font-size: 16px;
                }

                &.post-type {
                    margin-bottom: 5px;
                }
            }

            &:hover {
                &:nth-child(even){
                    background-color: darken(@main_colour_alt_light, 4%);
                }

                &:nth-child(3){
                    background-color: darken(@tertiary_colour, 4%);
                }
            }


            &:nth-child(even) {
                background-color: @main_colour_alt_light;
                p {
                    &.link {
                        color: @grey3;
                    }
                }
            }

            &:nth-child(3) {
                background-color: @tertiary_colour;
                p {
                    &.link {
                        color: @main_colour_alt;;
                    }
                }
            }
        }
    }

    .pagination-block {
        .pagination {
            .pagination-buttons {
                a {
                    margin-right: 0;
                    margin-bottom: 15px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .product-intro-block {
        .download-button {
            max-width: 100%;
        }
    }

    .product-gallery-block {
        .product-image-slider {
            max-height: 190px;
        }

        &.slider-custom-controls {
            .controls {
                margin: 15px 0 0;
                position: relative;
                .slider-button {
                    margin-right: 15px;
                    a {
                        font-size: 22px;
                    }

                    &:last-of-type {
                        float: left;
                    }
                }
            }
        }
    }

    .logo-block {
        .logo-list {
            .logo {
                float: left;
                width: 100%;
                margin-right: 0;
            }
        }
    }

    .testimonial-block {
        .testimonial-slider-for {
            .overlay {
                .testimonial-excerpt {
                    font-size: 18px;
                    margin-bottom: 35px;
                }
            }
        }
    }
}

/*********************
    max-width 329
**********************/

@media screen and (max-width: 329px) {
    .product-benefits-block {
        ul {
            li {
                .icon {
                   width: 100%;
                   margin-bottom: 15px;
                }

                .text {
                    width: 100%;
                }
            }
        }
    }

    .testimonial-block {
        .testimonial-slider-for {
            .overlay {
                .testimonial-excerpt {
                    font-size: 16px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}


}//Close body-wrapper
