@charset "UTF-8";
/* CSS Document */

/*********************
    Body
**********************/
*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html, body {
    min-height: 100%;
}

body {
    overflow-x: hidden;
}

.body-wrapper {
    .clearfix();
}

.content {
    .clearfix();
}
/*********************
    Header
**********************/

header {
    display: block;
    position: relative;
    width: 1385px;
    max-width: 100%;
    margin: 0 auto;
    padding: 25px 30px;
    background-color: @white;
    z-index: 99999;
    .clearfix();

    .left {
        float: left;
    }

    .right {
        float: right;
    }

    .menu-trigger {
        display: none;
        padding: 14px 0;
        font-size: 18px;
        cursor: pointer;
    }

    .main-navigation {
        ul {
            float: left;
            .clean-list();
            li {
                display: inline-block;

                a {
                    display: block;
                    padding: 14px 20px 14px 0;
                    color: @nav_item_colour;

                    &:hover {
                        color: @nav_item_colour_hover
                    }

                    &:active {
                        @nav_item_colour_active;
                    }
                }

                &.has-children {
                    &:hover {
                        .nav-drop {
                            visibility: visible;
                            opacity: 1;
                            transition: 0.3s;
                            transition-delay: 0s;
                        }
                    }
                }

            }

            &.nav-drop {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                width: 100%;
                top: 98px;
                left: 0;
                border-top: 3px solid transparent;
                transition: 0.3s;
                transition-delay: 0.6s;

                .menu-content {
                    min-height: 433px;
                    padding: 45px;
                    p {
                        font-size: 30px;
                        font-weight: 300;
                        margin-bottom: 35px;
                    }

                    a {
                        color: @main_colour_alt;
                        text-decoration: underline;
                    }
                }

                .half {
                    float: left;
                    width: 49.9%;
                    width: ~"calc(50% - 1.5px)";
                    //border-right: 3px solid transparent;
                    margin-right: 3px;
                    transition: 0.1s;
                    &:last-of-type {
                        margin-right: 0;
                    }
                    &.bg-filled {
                        background: rgba(255, 255, 255, 0.95);

                        &:hover {
                            background: rgba(255, 255, 255, 1);
                        }
                    }
                }

                .nav-drop-item {
                    width: 49.9%;
                    width: ~"calc(50% - 1.5px)";
                    max-height: 215px;
                    min-height: 215px;
                    float: left;
                    margin: 0 3px 3px 0;
                    background: rgba(255, 255, 255, 0.95);
                    text-align: center;
                    padding: 25px 25px;
                    .clearfix();

                    &:hover {
                        background: rgba(255, 255, 255, 1);
                    }

                    img {
                        max-height: 50px;
                        display: block;
                        margin: 0 auto 25px;
                    }

                    p {
                        font-size: 14px;
                    }

                    .product-title {
                        font-size: 16px;
                        color: @main_colour_alt;
                        font-weight: 500;
                        margin-bottom: 0;
                    }

                    &:nth-child(even) {
                        margin-right: 0;
                    }

                    &:nth-child(3) {
                        margin-bottom: 0;
                    }

                    &:nth-child(4) {
                        margin-bottom: 0;
                    }
                }
            }

            &.tour-cta {
                li {
                    a {
                        color: @main_colour_alt;
                        padding: 14px 20px 14px 35px;
                    }
                }
            }
        }
    }
}

/*********************
    Footer
**********************/

footer {
    width: 100%;
    padding: 80px;
    background-color: @secondary_colour_alt;
    .clearfix();

    .left {
        float: left;
    }

    .right {
        float: right;
    }

    .footer-navigation {
        ul {
            .clean-list();
            li {
                display: inline-block;
                a {
                    color: @white;
                    padding: 0 20px 0 0;

                    &:hover {
                        color: @grey6;
                    }
                }
            }
        }
    }

    img {
        display: block;
        float: right;
        margin-bottom: 45px;
    }

    .footer-subnav {
        clear: both;
        ul {
            margin-bottom: 10px;
            .clean-list();
            li {
                color: @grey6;
                font-size: 12px;
                display: inline-block;
                margin-right: 15px;

                a {
                    display: block;
                    color: @grey6;
                    font-size: 12px;
                    &:hover {
                        color: @white;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .digital-agency {
        text-align: right;
        p {
            display: inline-block;
            margin-bottom: 0;
            color: @grey6;
            font-size: 12px;
            a {
                color: @grey6;
                font-size: 12px;
                &:hover {
                    color: @white;
                }
            }
        }
    }

    &.bottom {
        position: absolute;
        bottom: 0;
    }
}

/*********************
    Main
**********************/

.content.main {

}