@charset "UTF-8";
.body-wrapper {
    /*****************
    Pages
*****************/


    /***********************
            Homepage
    ***********************/
    /**********************
        Homepage Banner
    ***********************/
    .homepage-banner-block {
        background: url('@{img_dir}gradient-bg-large.png') no-repeat center;
        background-size: cover;
        min-height: 1285px;
        margin-top: -98px;

        p.h1 {
            margin-top: 98px;
        }

        p {
            color: @white;
        }

        .homepage-ctas {
            margin-top: 330px;

            .cta-container {
                position: relative;
                clear: both;

                &:last-of-type {
                    position: absolute;
                    margin-top: 200px;
                }

                .underlay {
                    width: 0;
                    height: 0;
                    border-left: 275px solid transparent;
                    border-right: 275px solid transparent;
                    border-bottom: 200px solid rgba(51,51,51,0.2);
                    position: absolute;
                    top: -250px;
                    left: 380px;
                    z-index: 1;
                    &:after {
                        content: '';
                        position: absolute;
                        left: -275px;
                        top: 200px;
                        width: 0;
                        height: 0;
                        border-top: 200px solid rgba(51,51,51,0.2);
                        border-left: 275px solid transparent;
                        border-right: 275px solid transparent;
                        z-index: 1;
                    }
                }

                .cta {
                    cursor: pointer;
                    width: 0;
                    height: 0;
                    border-left: 275px solid transparent;
                    border-right: 275px solid transparent;
                    border-bottom: 200px solid @main_colour;
                    position: relative;
                    top: -250px;
                    float: right;
                    z-index: 99;
                    transition: 0.3s;

                    &:hover {
                        margin-top: -25px;
                        border-bottom: 200px solid @white;
                        .front {
                            visibility: hidden;
                            opacity: 0;
                        }

                        .back {
                            visibility: visible;
                            opacity: 1;
                        }

                        &:after {
                            border-top: 200px solid @white;
                        }
                    }

                    .front {
                        position: absolute;
                        width: 300px;
                        height: 250px;
                        transform: translate(-50%, 50%);
                        -webkit-transform: translate(-50%, 50%);
                        -moz-transform: translate(-50%, 50%);
                        -ms-transform: translate(-50%, 50%);
                        -o-transform: translate(-50%, 50%);
                        z-index: 999;

                        img {
                            display: block;
                            margin: 0 auto;
                            max-width: 100%;
                        }
                    }

                    .back {
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        width: 300px;
                        height: 250px;
                        transform: translate(-50%, 25%);
                        -webkit-transform: translate(-50%, 25%);
                        -moz-transform: translate(-50%, 25%);
                        -ms-transform: translate(-50%, 25%);
                        -o-transform: translate(-50%, 25%);
                        z-index: 999;
                        img {
                            display: block;
                            margin: 0 auto 10px;
                            max-width: 100px;
                        }

                        p {
                            color: @grey3;
                            margin-bottom: 20px;
                            &.cta-heading {
                                font-size: 22px;
                                font-weight: 500;
                            }
                            &.round-button {
                                position: absolute;
                                bottom: -60px;
                                left: 50%;
                                margin-left: -25px;
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                background-color: @quaternary_colour_alt_dark;
                                i {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    margin-left: -8px;
                                    margin-top: -10px;
                                    color: @white;
                                    font-size: 14px;
                                }

                            }
                        }
                    }


                    &:after {
                        content: '';
                        position: absolute;
                        left: -275px;
                        top: 200px;
                        width: 0;
                        height: 0;
                        border-top: 200px solid @main_colour;
                        border-left: 275px solid transparent;
                        border-right: 275px solid transparent;
                        transition: 0.3s;
                    }
                }

                &:last-of-type {

                    .underlay {
                        left: 0;
                    }

                    .cta {
                        float: left;
                        border-bottom-color: @main_colour_alt;
                        &:after {
                            border-top-color: @main_colour_alt;
                        }

                        &:hover {
                            border-bottom-color: @white;
                            &:after {
                                border-top-color: @white;
                            }
                        }
                    }
                }
            }
        }
    }

    /**********************
      Testimonial Slider
     **********************/
    .testimonial-block {
        position: relative;

        h2 {
            position: absolute;
            top: 65px;
            text-align: center;
            width: 100%;
            display: block;
            z-index: 9999;
        }
        .testimonial-slider-for {
            display: none;
            margin: 0 auto;
            position: relative;
            max-height: 450px;
            overflow: hidden;
            z-index: 9;

            .testimonial {
                display: inline-block;
                height: 450px;
                background-size: cover;
                background-position: top left;
                background-repeat: no-repeat;
            }

            .overlay {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 65px;
                background: rgba(0, 0, 0, 0.6);

                .wrapper {
                    height: 100%;
                }

                .testimonial-excerpt {
                    font-size: 20px;
                    margin-bottom: 45px;
                }

                span {
                    color: @main_colour_alt;
                }
            }
        }

        .testimonial-slider-nav {
            display: none;
            position: absolute;
            bottom: 0;
            z-index: 100;
            width: 100%;
            min-height: 100px;

            .slick-list {
                max-width: 260px;
                margin: 0 auto;
            }

            img {
                display: inline-block;
                float: left;
                max-width: 80px;
                max-height: 50px;
                margin-right: 10px;
                transform: scale(0.9);
                outline: none;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            .slick-current {
                transform: scale(1.1);
                border-bottom: 2px solid @main_colour_alt;
            }
        }

        &.slider-custom-controls {
            .controls {
                position: absolute;
                transform: translate(-50%, -50%);
                top: 83%;
                left: 50%;
                width: 300px;
                z-index: 999;

                .slider-button {
                    float: left;
                    margin-left: -10px;

                    &:last-of-type {
                        float: right;
                        margin-right: -2px;
                    }

                    a {
                        color: @main_colour_alt;
                        display: block;
                        font-size: 16px;
                    }
                }
            }
        }
    }



    /***********************
            Nexus Suite
    ***********************/
    /**********************
      Product Showcase
    **********************/
    .product-showcase-block {
        .product {
            float: left;
            display: block;
            width: 50%;
            text-align: center;
            padding: 85px 45px;
            border: 1px solid @white;
            background-color: @tertiary_colour;
            cursor: pointer;

            &:hover {
                background-color: darken(@tertiary_colour, 4%);
            }
            img {
                display: block;
                margin: 0 auto 50px;
                max-width: 100%;
                width: 175px;

            }

            p {
                margin-bottom: 0;

                &.link {
                    color: @main_colour_alt;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .product-information-block {
        .thirds-columns {
            margin-top: 45px;
            .col {
                img {
                    display: block;
                    margin: 0 auto 35px;
                    height: 50px;
                }
                p {
                    &.title {
                        font-weight: 500;
                        font-size: 18px;
                    }
                }
            }
        }
    }



    /***********************
            Products
    ***********************/
    /**********************
      Product Logo Block
    **********************/
    .product-logo-block {
        background-color: @white;
        border: 2px solid @main_colour_alt;
        padding: 25px 0;
        max-width: 350px;
        width: 350px;
        position: absolute;
        left: 50%;
        margin-left: -175px;
        margin-top: -70px;
        z-index: 9999;
        -webkit-box-shadow: 10px 10px 2px 0px rgba(0,0,0,0.04);
        -moz-box-shadow: 10px 10px 2px 0px rgba(0,0,238,0.04);
        box-shadow: 10px 10px 2px 0px rgba(0,0,0,0.04);

        img {
            display: block;
            margin: 0 auto;
            max-height: 80px;
        }
    }

    /**********************
    Product Gallery Block
    **********************/
    .product-gallery-block {
        position: relative;
        .product-image-slider {
            margin: 0 auto;
            position: relative;
            max-height: 705px;
            overflow: hidden;

            img {
                max-width: 100%;
                width: auto;
                height: auto;
                position: relative;
                margin: 0 auto;
                vertical-align: top;
            }
        }

        &.slider-custom-controls {
            .controls {
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                width: 100%;
                z-index: 999;
                left: 0;

                .slider-button {
                    float: left;
                    margin-left: -15px;

                    &:last-of-type {
                        float: right;
                        margin-right: -15px;
                    }

                    a {
                        color: @main_colour_alt;
                        display: block;
                        font-size: 50px;
                    }
                }
            }
        }
    }




    /**********************
      Product Intro Block
    **********************/
    .product-intro-block {
        position: relative;
        cursor: pointer;
        padding-top: 130px !important;
        .download-button {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 15px;
            background-color: @tertiary_colour;
            max-width: 300px;
            a {
                p {
                    float: left;
                    max-width: 80%;
                    color: @main_colour_alt;
                    font-size: 16px;
                    font-weight: 500;
                    margin: 0;
                    transition: 0.1s;
                }

                i {
                    color: @main_colour_alt;
                    font-size: 30px;
                    position: absolute;
                    right: 15px;
                    bottom: 15px;
                }

                &:hover {
                    p {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    /**********************
      Product Logo Block
    **********************/
    .product-benefits-block {
        ul {
            .clean-list();
            li {
                float: left;
                width: 45%;
                width: ~"calc(50% - 60px)";
                margin: 0 120px 35px 0;
                padding: 0;

                &:nth-child(2n) {
                    margin-right: 0;
                }

                .icon {
                    color: @white;
                    float: left;
                    font-size: 30px;
                    width: 5%;
                    width: ~"calc(10% - 15px)";
                    margin-right: 30px;
                }

                .text {
                    float: left;
                    width: 85%;
                    width: ~"calc(90% - 15px)";

                    h3 {
                        font-size: 20px;
                        margin-bottom: 5px;
                    }
                    p {
                        margin-bottom: 0;
                    }
                }

                &:before {
                    display: none;
                }
            }
        }
    }


    /***********************
            Blog
    ***********************/
    /**********************
        Tab Navigation
    **********************/
    .tab-navigation-block {
        .tab-menu {
            ul {
                text-align: center;
                margin-top: -69px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                .clearfix();
                .clean-list();
                li {
                    float: left;
                    min-width: 250px;
                    padding-left: 0;

                    &:before {
                        display: none;
                    }

                    a {
                        display: block;
                        padding: 25px 15px;
                        background-color: rgba(255, 255, 255, 0.85);

                        &:hover {
                            background-color: @white;
                        }

                        &.active {
                            background-color: @white;
                        }
                    }
                }
            }
        }
    }

    /**********************
          Post-list
    **********************/
    .post-list-block {
        .post {
            width: 50%;
            float: left;
            padding: 115px 45px;
            background-color: @tertiary_colour;
            text-align: center;
            transition: 0.1s;
            cursor: pointer;

            h3 {
                font-size: 25px;
                max-width: 450px;
                margin: 0 auto 65px;
            }

            p {
                &.post-type {
                    font-size: 14px;
                    color: @grey6;
                }

                &.link {
                    font-size: 18px;
                    color: @main_colour_alt;

                    i {
                        font-size: 12px;
                    }
                }
            }

            &:hover {
                background-color: darken(@tertiary_colour, 4%);

                &:nth-child(2){
                    background-color: darken(@main_colour_alt_light, 4%);
                }

                &:nth-child(3){
                    background-color: darken(@main_colour_alt_light, 4%);
                }

                &:nth-child(6){
                    background-color: darken(@main_colour_alt_light, 4%);
                }
            }


            &:nth-child(2) {
                background-color: @main_colour_alt_light;
                p {
                    &.link {
                        color: @grey3;
                    }
                }
            }

            &:nth-child(3) {
                background-color: @main_colour_alt_light;
                p {
                    &.link {
                        color: @grey3;
                    }
                }
            }

            &:nth-child(6) {
                background-color: @main_colour_alt_light;
                p {
                    &.link {
                        color: @grey3;
                    }
                }
            }
        }
    }

    /**********************
      Post Feature Image
    **********************/
    .feature-image-block {
        img {
            max-width:100%;
            height: auto;
        }
    }
}

/***********************
        Contact
************************/
.Contact {
    .main-content-block {
        padding-bottom: 40px !important;
    }
}